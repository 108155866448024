import React from 'react'
import Layout from '../components/layout'
import {Section} from '../components/containers/Layout'

const NotFoundPage = () => (
  <Layout>
    <Section direction="column" align="center">
      <h1>Page Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
)

export default NotFoundPage
